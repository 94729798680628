



































































import { Component, Vue, Prop } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import moment from "moment";
import alert from "@/mixins/alert";
import _permissions from "@/constants/_permissions";
import { getDateObject } from "@/utils/dateUtils";
import constants from "@/constants/_message";
import { publishMeetingAlertContent } from "@/utils/notificationContent";

@Component({})
export default class BookMeetingList extends Vue {
  @Prop() public index: any;
  @Prop() public tabName: any;
  @Prop() public meeting: any;
  @Getter public currentUser: any;
  @Action public meetingRuleValidation: any;
  @Getter public meetingError: any;
  @Action public showHipaaAgreementModal: any;
  @Getter public rolePerm: any;
  @Getter public role!: any;
  @Action public updateMeeting: any;
  perm = _permissions;
  enableBookButton: any = false;
  scheduledOpenTime: any;

  meetingId: any;
  quickBookMeeting: any = {};

  beforeMount() {
    this.getScheduledOpen();
  }

  getMeetingTime() {
    return !moment(this.meeting.start_time).isDST()
      ? `${moment(this.meeting.start_time)
          .add(1, "hour")
          .format("hh:mm A")} - ${moment(this.meeting.end_time)
          .add(1, "hour")
          .format("hh:mm A")}`
      : `${moment(this.meeting.start_time).format("hh:mm A")} - ${moment(
          this.meeting.end_time
        ).format("hh:mm A")}`;
    // return this.meeting
    //   ? `${moment(this.meeting.start_time).isDST()} - ${moment(this.meeting.start_time).format("hh:mm A")} - ${moment(
    //       this.meeting.end_time
    //     ).format("hh:mm A")}`
    //   : "";
  }

  getMeetingRefreshment() {
    if (this.meeting.appointment_label !== null) {
      return this.meeting.appointment_label &&
        this.meeting.appointment_label.length >= 30
        ? this.meeting.appointment_label.slice(0, 30) + "..."
        : this.meeting.appointment_label;
    } else {
      return this.meeting.refreshment && this.meeting.refreshment.length >= 30
        ? this.meeting.refreshment.slice(0, 30) + "..."
        : this.meeting.refreshment;
    }
  }

  onBookMeeting(id: any) {
    if (this.currentUser.is_medical_rep) {
      let currentTime = moment.utc(
        moment().format("YYYY-MM-DD[T]HH:mm[Z]"),
        "YYYY-MM-DD[T]HH:mm[Z]"
      );
      let subscribtionStartDate = moment.utc(
        this.currentUser.tenant.subscription_start_date,
        "YYYY-MM-DD[T]HH:mm[Z]"
      );
      if (this.currentUser.tenant.subscription_grace_days) {
        subscribtionStartDate = subscribtionStartDate.add(
          this.currentUser.tenant.subscription_grace_days,
          "days"
        );
      }
      if (
        this.currentUser.userTypesId === 3 &&
        this.currentUser.tenant.enable_subscription &&
        currentTime.isSameOrAfter(subscribtionStartDate)
      ) {
        if (
          this.currentUser.subscription_end_date &&
          this.currentUser.subscription_end_date !== ""
        ) {
          let subscriptionTime = moment.utc(
            this.currentUser.subscription_end_date,
            "YYYY-MM-DD[T]HH:mm[Z]"
          );
          if (this.currentUser.tenant.subscription_grace_days) {
            subscriptionTime = subscriptionTime.add(
              this.currentUser.tenant.subscription_grace_days,
              "days"
            );
          }
          subscriptionTime.isSameOrAfter(currentTime)
            ? this.isHipaaSigned(id)
            : alert.methods.appToaster({
                message:
                  "Your subscription is expired, Renew subscription to continue our services",
                type: "ERROR",
              });
        } else {
          alert.methods.appToaster({
            message: "You don't have a subscribed plan. Kindly subscribe.",
            type: "WARNING",
            noAutoClose: true,
          });
        }
      } else {
        this.isHipaaSigned(id);
      }
    } else {
      this.validateMeetingRule(id);
    }
  }

  isHipaaSigned(id: any) {
    if (
      this.currentUser.tenant.tenantpreferences.required_fields
        .enable_hipaa_form &&
      this.currentUser.tenant.tenantpreferences.required_fields
        .sign_hipaa_form &&
      (!this.currentUser.userhipaaform.hipaa_form_url ||
        (this.currentUser.userhipaaform.hipaa_form_url &&
          this.currentUser.userhipaaform.hipaa_form_url.length == 0))
    ) {
      this.showHipaaAgreementModal();
    } else {
      this.isVaccinationStatusNeeded(id);
    }
  }

  isVaccinationStatusNeeded(id: any) {
    if (
      this.currentUser.is_medical_rep &&
      this.currentUser.tenant.covid_vaccination !== null
    ) {
      if (this.currentUser.tenant.required_reps_vaccination) {
        if (
          this.currentUser.tenant.tenantpreferences.required_fields
            .vaccination_certificate_url
        ) {
          if (
            this.currentUser.vaccination_status === 1 &&
            this.currentUser.vaccination_certificate_url !== "" &&
            this.currentUser.vaccination_certificate_url !== null
          ) {
            this.validateMeetingRule(id);
          } else if (
            this.currentUser.vaccination_status === null ||
            (this.currentUser.vaccination_status == "" &&
              this.currentUser.vaccination_status !== 0)
          ) {
            this.$emit("onBookMeeting", this.meeting.id);
          } else {
            alert.methods.appToaster({
              message:
                "Vaccination and vaccination card are mandatory to continue booking appointments, Go to profile screen to update your vaccination status.",
              type: "ERROR",
            });
          }
        } else {
          if (this.currentUser.vaccination_status === 1) {
            this.validateMeetingRule(id);
          } else if (
            this.currentUser.vaccination_status === null ||
            this.currentUser.vaccination_status == 0 ||
            this.currentUser.vaccination_status == 2
          ) {
            this.$emit("onBookMeeting", this.meeting.id);
          } else {
            alert.methods.appToaster({
              message:
                "Vaccination is mandatory to continue booking appointments, Go to profile screen to update your vaccination status.",
              type: "ERROR",
            });
          }
        }
      } else {
        if (
          this.currentUser.vaccination_status ||
          this.currentUser.vaccination_status == 0
        ) {
          this.validateMeetingRule(id);
        } else {
          this.$emit("onBookMeeting", this.meeting.id);
        }
      }
    } else {
      this.validateMeetingRule(id);
    }
  }

  onQuickBookMeeting(id: any) {
    if (this.currentUser.is_medical_rep) {
      this.isHipaaSigned(id);
    } else {
      this.validateMeetingRule(id);
    }
  }

  validateMeetingRule(id: any) {
    this.meetingId = id;
    if (id) {
      this.meetingRuleValidation({
        data: { id },
        callback: this.ruleValidationSuccess,
      });
    } else if (this.isEnableQuickBook && this.isMedicalRep) {
      this.onSubmitQuickBook();
    } else {
      this.$router
        .push({ path: `/book-meeting/update/${this.meetingId}` })
        .catch();
    }
  }

  ruleValidationSuccess(state: any, updateError?: any) {
    if (state) {
      if (this.isEnableQuickBook && this.isMedicalRep) {
        this.onSubmitQuickBook();
      } else {
        this.$router
          .push({ path: `/book-meeting/update/${this.meetingId}` })
          .catch();
      }
    } else {
      if (updateError) {
        this.$emit("onMeetingsRemoved", this.meeting.id);
      }
    }
  }

  showPolicyAndPreference(): void {
    this.$emit("showPolicyAndPreference", this.meeting.locationId);
  }

  get isMedicalRep() {
    return (
      this.currentUser.is_medical_rep && this.currentUser.userTypesId === 3
    );
  }

  //Start: The quick book process started
  get isEnableQuickBook() {
    return (
      this.currentUser.tenant &&
      this.currentUser.tenant.tenantpreferences.required_fields &&
      this.currentUser.tenant.tenantpreferences.required_fields
        .enable_quick_book
    );
  }

  dateUtils(): void {
    const dateObject = getDateObject(this.dateObject);
    this.quickBookMeeting.start_time = dateObject.startDate;
    this.quickBookMeeting.end_time = dateObject.endDate;
  }

  currentDate = moment();
  remainder =
    15 -
    (this.currentDate.minute() % 15 === 0
      ? 15
      : this.currentDate.minute() % 15);
  roundedCurrentDate = moment(this.currentDate)
    .add(this.remainder, "minutes")
    .toISOString();

  dateObject = {
    startDate: this.roundedCurrentDate,
    endDate: this.roundedCurrentDate,
    startTime: this.roundedCurrentDate,
    endTime: moment(this.roundedCurrentDate).add(30, "minutes").toISOString(),
  };

  /**
   * Notify with Publish and Publish & notify while creating meeting
   */
  createMeetingAlert(): void {
    alert.methods.conFirmAlert(
      publishMeetingAlertContent,
      () => {
        this.dateUtils();
        this.quickBookMeeting = { ...this.meeting };
        delete this.quickBookMeeting.tenant_location;
        delete this.quickBookMeeting.zoom_history;
        delete this.quickBookMeeting.zoom_details;
        delete this.quickBookMeeting.appointment_label;
        delete this.quickBookMeeting.created_at;
        delete this.quickBookMeeting.appointmentTypeId;
        delete this.quickBookMeeting.updated_at;
        delete this.quickBookMeeting.created_by;
        delete this.quickBookMeeting.updated_by;
        delete this.quickBookMeeting.scheduled_open_at;
        this.quickBookMeeting.meeting_type = "broadcast";
        const uProduct = this.currentUser.products
          ? " - " + this.currentUser.products.split(",")[0]
          : "";
        this.quickBookMeeting.meeting_name = (
          this.currentUser.first_name + uProduct
        ).trim();
        this.quickBookMeeting.nonzoom_meeting_link =
          this.meeting.nonzoom_meeting_link.trim();
        this.quickBookMeeting.supporting_material = "";
        this.quickBookMeeting.other_restaurant = "Unknown restaurant";
        this.quickBookMeeting.restaurantsId = 9999;
        this.quickBookMeeting.status = 1;
        this.quickBookMeeting.is_notify = true;
        this.updateMeeting({
          data: this.quickBookMeeting,
          callback: this.backToMeetings,
        });
      },
      () => {}
    );
  }

  onSubmitQuickBook() {
    if (
      this.meeting.status &&
      moment(this.meeting.start_time).isBefore(moment())
    ) {
      alert.methods.appToaster({
        message: constants.START_TIME_ERROR_AT_SAVE,
        type: "ERROR",
      });
    } else {
      if (this.meeting.status === 0) {
        this.createMeetingAlert();
      }
    }
  }

  getScheduledOpen() {
    this.scheduledOpenTime =
      this.meeting.scheduled_open_at || moment().toISOString();
    const duration = moment.duration(
      moment(this.scheduledOpenTime).diff(moment.now())
    );
    const difference = duration.asMilliseconds();
    if (
      difference > 0
    ) {
      setTimeout(() => {
        this.enableBookButton = true;
      }, difference);
    }else{
      this.enableBookButton = true;
    }
  }

  backToMeetings() {
    this.$router.push({ name: "meeting.list" }).catch();
  }
  //End: The quick book process started
}
